import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { notFound } from 'next/navigation'
import { stringify } from 'qs'

import { SESSION_TOKEN_KEY_NAME, SESSION_TOKEN_PLAYTEST_KEY_NAME } from '@/src/app/config/constants'
import { deleteEmptyKeys } from '@/src/shared/lib/deleteEmptyKeys'
import { handleError } from '@/src/shared/lib/handleError'
import { logger } from '@/src/shared/lib/logger'

import { API_URL, API_URL_PLAYTEST, isDevEnv } from '../../app/config/env'
import { getSessionToken } from './storage'

export type IFormBody = Record<string, string | number>

export type IApiClientAdditionalOptions = {
  fetchConfig?: NextFetchRequestConfig
  nextCookies?: () => ReadonlyRequestCookies
}

export class ApiClient {
  private baseUrl: string
  private sessionTokenKeyName: string

  constructor(url: string, sessionTokenKeyName: string) {
    this.baseUrl = url
    this.sessionTokenKeyName = sessionTokenKeyName
  }

  private async handleResponse<TResult>(response: Response, url?: string): Promise<TResult> {
    if (!response.ok) logger.error(handleError(`HTTP error! Status: ${response.status}`), url)

    if (response.status === 404) notFound() // TODO: Think about it

    try {
      return await response.json()
    } catch (error) {
      logger.error(handleError(error), url)

      throw new Error('Error parsing JSON response')
    }
  }

  private getHeaders(cookies?: () => ReadonlyRequestCookies) {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${getSessionToken({ cookies, sessionTokenKeyName: this.sessionTokenKeyName })}`,
    }
  }

  public getFullUrl(url: string, params?: Record<string, unknown>) {
    const endpoint = new URL(url, this.baseUrl)
    const query = params ? stringify(deleteEmptyKeys(params)) : null

    return query ? `${endpoint.toString()}?${query}` : endpoint.toString()
  }

  public async get<TResult>(
    endpoint: string,
    queryParams?: Record<string, unknown>,
    nextConfig?: IApiClientAdditionalOptions,
  ): Promise<TResult> {
    const baseHeaders = this.getHeaders(nextConfig?.nextCookies)
    const fullUrl = this.getFullUrl(endpoint, queryParams)

    const tags = nextConfig?.fetchConfig?.tags ?? []
    const revalidate = nextConfig?.fetchConfig?.revalidate

    if (isDevEnv) logger.info(fullUrl)

    const response = await fetch(fullUrl, {
      headers: {
        ...baseHeaders,
      },

      next: {
        revalidate: revalidate ?? 10,
        tags: [fullUrl, ...tags],
      },
    })

    return this.handleResponse<TResult>(response, fullUrl)
  }

  public async post<TResult, TFormBody = unknown>(
    endpoint: string,
    body?: TFormBody,
    nextConfig?: IApiClientAdditionalOptions,
  ): Promise<TResult> {
    const baseHeaders = this.getHeaders(nextConfig?.nextCookies)
    const isFormData = body instanceof FormData

    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: 'POST',
      headers: {
        ...baseHeaders,
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      },

      ...(body ? { body: isFormData ? body : JSON.stringify(body) } : {}),
    })

    return this.handleResponse<TResult>(response)
  }
}

export const apiClient = new ApiClient(`${API_URL}/`, SESSION_TOKEN_KEY_NAME)

export const apiClientPlaytest = new ApiClient(
  `${API_URL_PLAYTEST}/`,
  SESSION_TOKEN_PLAYTEST_KEY_NAME,
)
