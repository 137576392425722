'use client'

import { useEffect } from 'react'
import useSWR, { preload } from 'swr'

import { setSessionToken } from '@/src/shared/api/storage'
import { isClient } from '@/src/shared/lib/isServerOrClient'

import { getSession } from '../api'
import { SESSION_REVALIDATE_KEY } from '../config'

if (isClient) {
  preload([SESSION_REVALIDATE_KEY], () => getSession())
}

export const useSession = () => {
  const { data } = useSWR([SESSION_REVALIDATE_KEY], () => getSession())

  const token = data?.token

  useEffect(() => {
    if (!token) return

    setSessionToken({ token })
  }, [token])

  return {
    status: data?.status,
    token,
  }
}
